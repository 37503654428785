.contact {
  flex-direction: column;
}

/* contact form styles */
/* all is outline style */
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.form__group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.form__input {
  padding: 0.8em 1.4em;
  font-size: 1rem;
  border: 2px solid var(--clr-primary);
  outline: none;
  transition: border 0.2s ease-in-out;
  margin: 0.5em 0;
}

.form__input:focus {
  border: 2px solid var(--clr-primary);
}

.form__input::placeholder,
.form__textarea::placeholder {
  color: var(--clr-fg-alt);
}

.form__textarea {
  width: 100%;
  padding: 0.8em 1.4em;
  font-size: 1rem;
  border: 2px solid var(--clr-primary);
  outline: none;
  transition: border 0.2s ease-in-out;
  margin: 0.5em 0;
}

.contact__divider {
  width: 100%;
  height: 1px;
  background-color: var(--clr-fg-alt);
  margin: 2em 0;
}

.contact__divider--text {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -0.8em;
  background-color: var(--clr-bg);
  padding: 0 1em;
  color: var(--clr-fg-alt);
}
